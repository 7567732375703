<template>
  <el-card class="v_money_store_recharge">
    <div class="v-money-store-head g-flex-align-center">
      <div class="v-money-store-head-card">
        <div class="v-money-store-head-card-top g-flex-align-center">
          <p class="v-money-store-head-card-top-title">收款账户：</p>
          <img src="/img/balance.png" alt="">
        </div>
        <div class="v-money-store-head-card-middle g-flex-column">
          <p class="v-money-store-head-card-middle-num">{{bankInfo.card_number}}</p>
          <span class="v-money-store-head-card-middle-bank">{{bankInfo.bank_name}}</span>
          <span class="v-money-store-head-card-middle-name">{{bankInfo.name}}</span>
        </div>
        <div class="v-money-store-head-card-bottom g-flex-column">
          <p class="v-money-store-head-card-bottom-title">账户余额</p>
          <span class="v-money-store-head-card-bottom-money">¥{{balance}}</span>
        </div>
      </div>
      <div class="v-money-store-recharge-tips-card">
        <span class="g-black g-font-bold">转账注意事项</span>
        <p class="g-red">暂不支持通过支付宝、微信转账（无法核对打款人姓名）</p>
        <p class="g-red">人工审核时间：周一至周六 09:00-21:00</p>
        <ul>
          <li>
            <span>1. 禁止使用支付宝、微信转账</span>
          </li>
          <li>
            <span>2. 要求先转账再提交转账信息</span>
          </li>
          <li>
            <span>4. 提交转账信息前仔细核对转账金额、姓名及银行卡号，禁止重复提交转账信息</span>
          </li>
          <li>
            <span>5. 及时提交转账信息，未及时提交者(超过一天)请联系客服说明</span>
          </li>
        </ul>
      </div>
    </div>
    
    
    <h3 class="v-money-store-recharge-tips g-flex-align-center">
      <img src="/img/gantan.png" alt="">
      收款账号不定时更换，请注意核对，否则无法到账
    </h3>

    <el-form>
      <!-- 收款账户 -->
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="收款账户：" label-width="160px">
            <span class="g-red g-fsize18">{{bankInfo.card_number}} {{bankInfo.name}} <span class="g-padleft15 g-black g-fsize14">开户行: {{bankInfo.bank_name}}</span></span>
            <p class="g-blue">请使用手机银行付款充值，单笔充值最低100，最高500000，高额/其他充值联系客服</p>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 充值金额 -->
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="充值金额：" label-width="160px">
              <el-row :gutter="20">
                 <el-col :span="3" class="g-flex-align-center">
                    <el-input class="g-flex-1" v-model="form.money" size="small"></el-input>
                 </el-col>
                 <el-col :span="18" class="g-flex-align-center">
                   <span class="g-blue">转账金额附加零头（如 1000.32），可加快财务审核</span>
                 </el-col>
              </el-row>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 转账银行 -->
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="转账银行：" label-width="160px">
              <el-row :gutter="20">
                 <el-col :span="3" class="g-flex-align-center">
                    <el-input class="g-flex-1" v-model="form.bank_name" size="small"></el-input>
                 </el-col>
                 <el-col :span="18" class="g-flex-align-center">
                   <span class="g-grey">请输入您使用的转出银行卡银行名称，如：<span class="g-blue">工商银行</span> </span>
                 </el-col>
              </el-row>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 转账银行卡号 -->
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="转账银行卡号：" label-width="160px">
              <el-row :gutter="20">
                 <el-col :span="6" class="g-flex-align-center">
                    <el-input class="g-flex-1" v-model="form.card_number" size="small"></el-input>
                 </el-col>
                 <el-col :span="16" class="g-flex-align-center">
                   <span class="g-grey"> 请输入您使用的转出银行卡银行卡号，如：<span class="g-blue">6212263100000000000</span> </span>
                 </el-col>
              </el-row>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 转出开户名 -->
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="转出开户名：" label-width="160px">
              <el-row :gutter="20">
                 <el-col :span="3" class="g-flex-align-center">
                    <el-input class="g-flex-1" v-model="form.name" size="small"></el-input>
                 </el-col>
                 <el-col :span="18" class="g-flex-align-center">
                   <span class="g-grey"> 请输入您使用的转出银行卡的开户人姓名，如：<span class="g-blue">张三</span> </span>
                 </el-col>
              </el-row>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="v-money-store-recharge-btn" v-if="auth.recharge">
       <el-button style="margin-top: 12px;" type="primary" @click="submitClick">确认提交</el-button>

      </div>

    </el-form>


  </el-card>
</template>

<script>
  import { apiGetUserBalance, apiAddRecharge } from '@/utils/api.js'
  export default {
    data() {
      return {
        auth: {
          recharge: this.$global.ApiList.includes(50), //充值
        },
        bankInfo: this.$global.config.bank,
        balance: '',
        form: {
          money: '',
          bank_name: '',
          name: '',
          card_number: ''
        }
      }
    },
    created() {
      let localRechageInfo = window.localStorage.getItem('rechageInfo')
      if(localRechageInfo) {
        this.form = JSON.parse(localRechageInfo)
      }
      this.apiGetUserBalanceHandel()
    },
    methods: {
      submitClick() {
        this.apiAddRechargeHandel()
        console.log('确认提交')
      },

      // 商家充值
      async apiAddRechargeHandel() {
        let loading = this.$loading()
        const { success, data } = await apiAddRecharge(this.form).catch(() => loading.close())
        loading.close()
        if(!success) return
        this.$message.success(data.msg)
        window.localStorage.setItem('rechageInfo', JSON.stringify(this.form))
        console.log(data)
        this.$router.push({ path: '/moneystore/moneystorechargelist'})
      },
      
      // 获取余额
      async apiGetUserBalanceHandel() {
        const { success, data } = await apiGetUserBalance()
        if(!success) return
        this.balance = data.balance
        console.log(data)
      }
    }
  }
</script>

<style lang="scss">
.v_money_store_recharge {
  background: #F2F5FA;
  .v-money-store-head {
    .v-money-store-head-card {
      width: 580px;
      height: 400px;
      padding-left: 60px;
      padding-right: 60px;
      background: url("/img/recharge_bg.png") no-repeat;
      background-position: center;
      background-size: cover;
      .v-money-store-head-card-top {
        padding-top: 50px;
        .v-money-store-head-card-top-title {
          font-size: 24px;
          color: $white;
          flex: 1;
        }
        img {
          width: 70px;
          height: 60px;
          object-fit: cover;
          vertical-align: middle;
        }

      }
      .v-money-store-head-card-middle {
        padding-top: 10px;
        color: $white;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 26px;
        .v-money-store-head-card-middle-bank,.v-money-store-head-card-middle-name {
          padding-top: 6px;
          font-size: 20px;
        } 
      }
      .v-money-store-head-card-bottom {
        padding-top: 40px;
        color: $white;
        font-size: 24px;
        .v-money-store-head-card-bottom-money {
          font-weight: bold;
          font-size: 40px;
          padding-top: 20px;
        }
      }
    }
    .v-money-store-recharge-tips-card {
      font-size: 16px;
      background: $white;
      line-height: 200%;
      padding: 30px 30px 30px 45px;
      border-radius: 8px;
      display: inline-block;
      margin-top: 40px;
      margin-bottom: 30px;
      margin-left: 30px;
      height: 330px;
      flex: 1;
    }

  }
  .v-money-store-recharge-tips {
    padding: 14px 40px;
    margin: 0;
    margin-bottom: 10px;
    border-radius: 15px;
    color: #F60D33;
    background: #040848;
    font-size: 24px;
    font-weight: normal;
    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .v-money-store-recharge-btn {
    padding-left: 160px;
    .el-button {
      padding: 10px 50px;
    }
  }
}
</style>